import { useEffect, useState } from "preact/hooks";
import { Chart as ChartJS } from "chart.js/auto";
import { Bar, Doughnut } from "react-chartjs-2";

import style from "./style";

export default function Dashboard(props) {
  const [total, setTotal] = useState(0);
  const [hondTotal, setHondTotal] = useState(0);
  const [katTotal, setKatTotal] = useState(0);
  const [hondPerc, setHondPerc] = useState(0);
  const [katPerc, setKatPerc] = useState(0);
  const [naam, setNaam] = useState("");

  useEffect(async () => {
    let response = await fetch("https://astrego.dev/api/get_results.php");
    response = await response.json();
    const kat = response.totaal - response.totaal_hond;
    setTotal(response.totaal);
    setHondTotal(response.totaal_hond);
    setKatTotal(kat);

    const hondP = parseInt((response.totaal_hond / response.totaal) * 100);
    const katP = 100 - hondP;
    setHondPerc(hondP);
    setKatPerc(katP);
  }, [naam]);

  let labels;

  if (props.language === "nl ") {
    labels = [
      "totaal",
      String.fromCodePoint(128054),
      String.fromCodePoint(128049),
    ];
  } else {
    labels = [
      "total",
      String.fromCodePoint(128054),
      String.fromCodePoint(128049),
    ];
  }

  let label;
  if (props.language === "nl") {
    label = "Honden vs Katten";
  } else {
    label = "Dogs vs Cats";
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: [total, hondTotal, katTotal],
        backgroundColor: ["#ffd700", "#ff3385", "dodgerblue"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          font: {
            size: 18,
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  const dataD = {
    labels: [String.fromCodePoint(128054), String.fromCodePoint(128049)],
    datasets: [
      {
        label: label,
        data: [hondPerc, katPerc],
        backgroundColor: ["#ff3385", "dodgerblue"],
        hoverOffset: 4,
      },
    ],
  };

  const optionsD = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: {
            size: 20,
          },
        },
      },
    },
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const saveObject = {
      data: [
        {
          naam: naam,
          hond_kat: e.submitter.name,
        },
      ],
    };

    console.log(saveObject);

    let response = await fetch(`https://astrego.dev/api/new_hond_kat.php`, {
      method: "POST",
      credentials: "omit",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(saveObject),
    });

    response = await response.text();

    if (response === "ok") {
      setNaam("");
    }
  };

  return (
    <>
      <div class={style.container}>
        <div class={style.menu}>
          <form onSubmit={submitHandler}>
            <p class={style.titel}>
              {props.language === "nl" ? "Hond" : "Dog"}
              <span> &#10084;</span>
              {props.language === "nl" ? "f Kat?" : "r Cat?"}
            </p>
            <input
              type="text"
              class={style.naam}
              placeholder={props.language === "nl" ? "Naam" : "Name"}
              required
              value={naam}
              onChange={(e) => setNaam(e.target.value)}
            />
            <input
              type="submit"
              name="hond"
              class={style.hondButton}
              value={String.fromCodePoint(128054)}
            />
            <input
              type="submit"
              name="kat"
              class={style.katButton}
              value={String.fromCodePoint(128049)}
            />
          </form>
        </div>
        <div class={style.chart}>
          <Bar data={data} options={options} />
        </div>
        <div class={style.chart}>
          <Doughnut data={dataD} options={optionsD} />
        </div>
      </div>
    </>
  );
}
