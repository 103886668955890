import Container from "./components/container";
import "./style";

export default function App() {
  return (
    <div>
      <Container />
    </div>
  );
}
