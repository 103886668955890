import style from "./style";

export default function Profile(props) {
  const nl = {
    p1: "Mijn naam is Karlan Astrego, ik ben gespecialiseerd in het ontwikkelen van webapps.",
    p2: "Ik heb ervaring met onder andere de volgende webtechnologieën:",
    p3: "Ik kan voor u complete websites, apps of API's bouwen maar ook mini applicaties die u kunt integreren op uw website. Hieronder ziet u een voorbeeld. Geef gerust uw voorkeur door.",
  };

  const en = {
    p1: "My name is Karlan Astrego, I am specialized in developing web applications.",
    p2: "I have experience with the following technologies:",
    p3: "I can build for you, complete websites, apps, or API's but also small applications that you can integrate into your website. See below for an example of a small dashboard. Feel free to submit your preference.",
  };

  return (
    <div class={style.profile}>
      <h2>Info:</h2>
      <p>{props.language === "nl" ? nl.p1 : en.p1}</p>
      <p>{props.language === "nl" ? nl.p2 : en.p2}</p>
      <ul class={style.tech}>
        <li>Javascript</li>
        <li>HTML</li>
        <li>CSS</li>
        <li>PHP</li>
        <li>React</li>
        <li>NodeJS</li>
        <li>SQL</li>
        <li>MongoDB</li>
      </ul>
      <p>{props.language === "nl" ? nl.p3 : en.p3}</p>
    </div>
  );
}
