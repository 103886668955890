import style from "./style";

export default function Header(props) {
  return (
    <header>
      <a href="/" class={style.logo}>
        Astre<span>go</span>
      </a>
      <div class={style.menu}>
        <a href="#" onClick={() => props.click("nl")}>
          nl
        </a>
        <span>|</span>
        <a href="#" onClick={() => props.click("en")}>
          en
        </a>
      </div>
    </header>
  );
}
