import { useState } from "preact/hooks";
import style from "./style";

import Profile from "../profile";
import Header from "../header";
import Contact from "../contact";
import Dashboard from "../dashboard";

export default function Container() {
  const [taal, setTaal] = useState("nl");

  const test = (lan) => {
    setTaal(lan);
  };

  return (
    <>
      <Header click={test} />
      <div class={style.container}>
        <Profile language={taal} />
        <Contact language={taal} />
        <Dashboard language={taal} />
      </div>
    </>
  );
}
