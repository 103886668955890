import style from "./style";

import { FaGithub, FaLinkedin } from "react-icons/fa";
import Terms from "../../alg/alg.pdf";

export default function Contact(props) {
  const nl = {
    adres: "Adres:",
    postcode: "Postcode:",
    alg_voorwaarden: "Algemene Voorwaarden",
  };

  const en = {
    adres: "Address:",
    postcode: "ZIP code:",
    alg_voorwaarden: "Terms and Conditions (Dutch)",
  };

  return (
    <div class={style.contact}>
      <h2>Contact:</h2>
      <p>Email: info@astrego.dev</p>
      <p>
        {props.language === "nl" ? nl.adres : en.adres} Zacharias Jansestraat 2
        A
      </p>
      <p>
        {props.language === "nl" ? nl.postcode : en.postcode} 1097 CL Amsterdam
      </p>
      <p>KvK: 85865931</p>
      <p>BTW: NL004159429B34</p>
      <a target="_blank" href={Terms} class={style.terms}>
        {props.language === "nl" ? nl.alg_voorwaarden : en.alg_voorwaarden}
      </a>
      <div class={style.socials}>
        <a
          class={style.github}
          target="_blank"
          href="https://github.com/astrego"
        >
          <FaGithub size={26} class={style.logo} />
        </a>
        <a
          class={style.linkedin}
          target="_blank"
          href="https://www.linkedin.com/in/karlanastrego/"
        >
          <FaLinkedin size={26} class={style.logo} />
        </a>
      </div>
    </div>
  );
}
